import {
  Button,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Menu, Box, Typography, Chip } from "@mui/material";
import React, { useState } from "react";
import NewTextField from "src/components/form/TextField";
import MyDatePicker from "src/components/pickers/DatePicker";
import StatusDropdown from "src/pages/Component/dropDown";
import { fDate } from "src/utils/formatTime";
import { useSearchParams } from "react-router-dom";
import ChevronRightIcon from "src/assets/icon/chevron/right";
import Multiply from "src/assets/icon/multiply/Multiply";
import {
  TFilterState,
  getFieldsSearchParams,
  getPaymentStatusSearchParams,
} from "./hooks/useFilter";
import { ReactComponent as SearchIcon } from "src/assets/icon/search.svg";
import CustomLoader from "src/components/loading/loadingContainer";
import { ReactComponent as FilterIcon } from "src/assets/icon/filter.svg";
import { varFade } from "src/components/animate";
import { m } from "framer-motion";
import { Entries } from "./hooks/usePageQuery";
import PlusIcon from "src/assets/icon/plus";
import { createFilterOptions, GlobalStyles, useTheme } from "@mui/material";
import { NewAutoCompleteField } from "src/components/form/AutocompleteField";
import { globalStyles } from "src/pages/formvalidation/Component/styles";
import MySwitchField from "src/components/form/switch";
import { store } from "src/redux/store";

type TFilter = {
  name: string;
  value: string;
  type: string;
  options: string[];
  label: string;
  required?: boolean;
  disabled?: boolean;
  defaultValue?: string | null | number | boolean;
  filterState?: TFilterState;
  setFilterState?: any;
  displayValue?: string;
  fields?: [];
  documentFieldToggle?: boolean;
};

const SearchFilters = ({
  handleSubmit,
  optionsApprovalStatus,
  optionsStatus,
  loader,
  translate,
  searchApi,
  filterState,
  setFilterState,
  optionsIntegrationStatus,
  documentFilterFields: fields,
  documentFieldToggle,
}) => {
  const {
    org: { enablePaymentFlow },
  } = store.getState()?.organization;

  const paymentStatusOptions = ["Due", "Paid", "UnPaid", "Process"];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const optionsStatusMap = {
    "": `${translate(`finance_screen.all`)}`,
    "1": `${translate(`status.processed`)}`,
    "4": `${translate(`status.need_review`)}`,
    "5": `${translate(`status.reviewed`)}`,
    "6": `${translate(`status.duplicate`)}`,
    "2": `${translate(`status.failed`)}`,
  };

  const optionsApprovalStatusMap = {
    "": `${translate(`finance_screen.all`)}`,
    "0": `${translate(`Approval required`)}`,
    "99999": `${translate(`approval not required`)}`,
    "1": `${translate(`approved`)}`,
    "2": `${translate(`rejected`)}`,
  };

  const optionsIntegrationStatusMap = {
    "": `${translate(`all`)}`,
    true: `${translate(`Yes`)}`,
    false: `${translate(`No`)}`,
  };

  const [fieldsValue, setFieldsValue] = useState<{
    fieldName: string;
    value: string;
  }>({
    fieldName: "",
    value: "",
  });
  const [initialValues, setInitialValues] = useState<any>(filterState);

  const [filters, setFilters] = useState<TFilter[]>([
    ...(searchParams.get("invoiceStatus") != null
      ? [
          {
            name: "invoiceStatus",
            value: searchParams.get("invoiceStatus") ?? "",
            displayValue:
              optionsStatusMap[searchParams.get("invoiceStatus") ?? ""],
            type: "dropdown",
            options: [],
            defaultValue: ``,
            label: `Document status`,
          },
        ]
      : []),
    ...(searchParams.get("fromDate") != null
      ? [
          {
            name: "fromDate",
            value: searchParams.get("fromDate") ?? "",
            displayValue: fDate(searchParams.get("fromDate") ?? ""),
            type: "date",
            options: [],
            defaultValue: null,
            label: `Start date`,
          },
        ]
      : []),
    ...(searchParams.get("toDate") != null
      ? [
          {
            name: "toDate",
            value: searchParams.get("toDate") ?? "",
            displayValue: fDate(searchParams.get("toDate") ?? ""),
            type: "date",
            options: [],
            defaultValue: null,
            label: `End date`,
          },
        ]
      : []),
    ...(searchParams.get("documentName") != null
      ? [
          {
            name: "documentName",
            value: searchParams.get("documentName") ?? "",
            displayValue: searchParams.get("documentName") ?? "",
            type: "text",
            defaultValue: null,
            options: [],
            label: `Document name`,
          },
        ]
      : []),
    ...(searchParams.get("approvalStatus") != null
      ? [
          {
            name: "approvalStatus",
            value: searchParams.get("approvalStatus") ?? "",
            displayValue:
              optionsApprovalStatusMap[
                searchParams.get("approvalStatus") ?? ""
              ] ?? "",
            type: "dropdown",
            defaultValue: "",
            options: [],
            label: `Approval status`,
          },
        ]
      : []),
    ...(searchParams.get("isERPIntegrated") != null
      ? [
          {
            name: "isERPIntegrated",
            value: searchParams.get("isERPIntegrated") ?? "",
            displayValue:
              optionsIntegrationStatusMap[
                searchParams.get("isERPIntegrated") ?? ""
              ] ?? "",
            type: "checkbox",
            options: [],
            defaultValue: "",
            label: "ERP integrated",
          },
        ]
      : []),
    ...(searchParams.get("includeDeleted") != null
      ? [
          {
            name: "includeDeleted",
            value: searchParams.get("includeDeleted") ?? "",
            displayValue:
              searchParams.get("includeDeleted") == "true" ? "Yes" : "No",
            type: "checkbox",
            options: [],
            defaultValue: "",
            label: "Include deleted documents",
          },
        ]
      : []),
    ...getPaymentStatusSearchParams(searchParams)?.map((item: string) => ({
      name: `paymentStatus.${item}`,
      value: item,
      displayValue: item,
      type: "select",
      options: [],
      defaultValue: "",
      label: translate("payment_status"),
    })),
    ...(Array.from(searchParams.keys())?.filter((item) =>
      item.startsWith("fields")
    )?.length > 0
      ? getFieldsSearchParams(searchParams).map((item) => {
          return {
            name: `fields.${item?.fieldName}`,
            value: item?.value,
            displayValue: item?.value,
            type: "text",
            options: [],
            defaultValue: "",
            label: `${item?.fieldName}`,
          };
        })
      : []),
  ]);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleChange = (name: string, value: any) => {
    setInitialValues({
      ...initialValues,
      [name]: value,
    });
  };

  const filterSettersMap = {
    invoiceStatus: (value = "") => {
      let filter = filters.find((item) => item.name === "invoiceStatus");
      if (filter) {
        filter.value = value;
        filter.displayValue = optionsStatusMap[value];
      } else {
        filters.push({
          name: "invoiceStatus",
          value: value,
          displayValue: optionsStatusMap[value],
          type: "dropdown",
          options: [],
          defaultValue: ``,
          label: `Document status`,
        });
      }
    },
    fromDate: (value = "") => {
      let filter = filters.find((item) => item.name === "fromDate");
      if (filter) {
        filter.value = fDate(value);
        filter.displayValue = fDate(value);
      } else {
        filters.push({
          name: "fromDate",
          value: fDate(value),
          displayValue: fDate(value),
          type: "date",
          options: [],
          defaultValue: null,
          label: `Start date`,
        });
      }
    },
    toDate: (value = "") => {
      let filter = filters.find((item) => item.name === "toDate");
      if (filter) {
        filter.value = fDate(value);
        filter.displayValue = fDate(value);
      } else {
        filters.push({
          name: "toDate",
          value: fDate(value),
          displayValue: fDate(value),
          type: "date",
          options: [],
          defaultValue: null,
          label: `End date`,
        });
      }
    },
    documentName: (value = "") => {
      let filter = filters.find((item) => item.name === "documentName");
      if (filter) {
        filter.value = value;
        filter.displayValue = value;
      } else {
        filters.push({
          name: "documentName",
          value: value,
          displayValue: value,
          type: "text",
          options: [],
          defaultValue: "",
          label: `Document name`,
        });
      }
    },
    approvalStatus: (value = "") => {
      let filter = filters.find((item) => item.name === "approvalStatus");
      if (filter) {
        filter.value = value;
        filter.displayValue = optionsApprovalStatusMap[value];
      } else {
        filters.push({
          name: "approvalStatus",
          value: value,
          displayValue: optionsApprovalStatusMap[value],
          type: "dropdown",
          options: [],
          defaultValue: "",
          label: `Approval status`,
        });
      }
    },
    isERPIntegrated: (value = "") => {
      let filter = filters.find((item) => item.name === "isERPIntegrated");
      if (filter) {
        filter.value = value;
        filter.displayValue = optionsIntegrationStatusMap[value];
      } else {
        filters.push({
          name: "isERPIntegrated",
          value: value,
          displayValue: optionsIntegrationStatusMap[value],
          type: "checkbox",
          options: [],
          defaultValue: false,
          label: "ERP integrated",
        });
      }
    },
    includeDeleted: (value = "") => {
      let filter = filters.find((item) => item.name === "includeDeleted");
      if (filter) {
        filter.value = value;
        filter.displayValue = value.toString() === "true" ? "Yes" : "No";
      } else {
        filters.push({
          name: "includeDeleted",
          value: value,
          displayValue: value.toString() === "true" ? "Yes" : "No",
          type: "checkbox",
          options: [],
          defaultValue: false,
          label: "Include deleted documents",
        });
      }
    },
    paymentStatus: (value = "") => {
      let filter = filters.find((item) => item.value === value);

      if (!filter) {
        filters.push({
          name: `paymentStatus.${value}`,
          value: value,
          displayValue: value,
          type: "select",
          options: [],
          defaultValue: "",
          label: translate("payment_status"),
        });
      }
    },

    fields: (value = {}) => {
      fields?.forEach((fieldKey: string) => {
        if (value[fieldKey]) {
          let filter = filters.find(
            (item) => item.name === `fields.${fieldKey}`
          );
          if (filter) {
            filter.value = value[fieldKey] as string;
            filter.displayValue = value[fieldKey] as string;
          } else {
            filters.push({
              name: `fields.${fieldKey}`,
              value: value[fieldKey] as string,
              displayValue: value[fieldKey] as string,
              type: "text",
              options: [],
              defaultValue: "",
              label: `${fieldKey}`,
            });
          }
        } else {
          let filter = filters.find(
            (item) => item.name == `fields.${fieldKey}`
          );
          if (filter) {
            filters.splice(filters.indexOf(filter), 1);
          }
        }
      });
    },
  };

  const handleFilters = (initialValues: any) => {
    (
      Object.entries(initialValues) as Entries<{
        [key: string]:
          | string
          | number
          | boolean
          | null
          | Record<string, string>
          | string[]
          | undefined;
      }>
    ).forEach(([key, value]) => {
      if (value) {
        if (Array.isArray(value)) {
          value.forEach((paymentStatus) => {
            filterSettersMap?.[key]?.(paymentStatus);
          });
        } else {
          filterSettersMap?.[key]?.(value);
        }
      } else {
        let filter = filters.find((item) => item.name == key);
        if (filter) {
          filters.splice(filters.indexOf(filter), 1);
        }
      }
    });
    setFilters(filters);
  };

  const handleReset = () => {
    let state = {
      documentClass: filterState?.documentClass,
      invoiceStatus: "",
      approvalStatus: "",
      fromDate: "",
      toDate: "",
      documentName: "",
      isERPIntegrated: "",
      includeDeleted: "",
      fields: {},
      paymentStatus: [],
    };
    setFilterState(state);
    setInitialValues(state);
    setFilters([]);
    searchApi(state);
    handleCloseMenu();
  };

  return (
    <>
      <Box
        onClick={(event) => {
          handleOpenMenu(event);
        }}
        sx={{
          borderRadius: "4px",
          width: {
            xs: "100%",
            sm: "100%",
            md: "70%",
            lg: "60%",
            xl: "50%",
          },
          display: "flex",
          alignItems: "center",
          gap: "4px",
          justifyContent: "space-between",
          border: `1px solid ${theme?.palette?.secondary?.main}`,
          p: 0.5,
          my: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <span>
            <SearchIcon
              style={{
                stroke: theme?.palette?.secondary?.main,
                height: "20px",
                width: "20px",
              }}
            />
          </span>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "4px",
              pointerEvents: "none",
              color: "#657482",
              zIndex: 10,
              overflow: "hidden",
            }}
          >
            {filters?.map((item, index) => {
              return (
                <Chip
                  key={`chip-${item?.name}-${index}`}
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                        flexDirection: "row",
                      }}
                    >
                      {translate(item?.label)}
                      <ChevronRightIcon height={10} width={10} />{" "}
                      {translate(item?.displayValue)}
                    </Box>
                  }
                  size="small"
                  onDelete={() => {
                    let newFilters = [...filters];
                    let filter = newFilters.splice(index, 1);
                    let state: any = {};
                    if (filter[0]?.name?.startsWith("fields")) {
                      let newFieldObj = { ...initialValues.fields };
                      delete newFieldObj[filter[0]?.name.split(".")[1]];
                      state = {
                        ...initialValues,
                        fields: newFieldObj,
                        documentClass: filterState?.documentClass,
                      };
                    } else if (filter[0].name?.startsWith("paymentStatus")) {
                      let updatedPaymentStatus =
                        initialValues.paymentStatus.filter(
                          (item: string) => item !== filter[0].value
                        );
                      state = {
                        ...initialValues,
                        paymentStatus: updatedPaymentStatus,
                        documentClass: filterState?.documentClass,
                      };
                    } else {
                      state = {
                        ...initialValues,
                        [filter[0]?.name]: filter[0]?.defaultValue,
                        documentClass: filterState?.documentClass,
                      };
                    }
                    setFilterState(state);
                    setInitialValues(state);
                    setFilters(newFilters);
                    searchApi(state);
                  }}
                  deleteIcon={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: 15,
                        height: 15,
                      }}
                    >
                      <Multiply
                        height={10}
                        width={10}
                        customColor={theme?.palette?.action.active}
                      />
                    </Box>
                  }
                  sx={{
                    borderRadius: "4px",
                    height: "20px",
                    fontSize: "10px",
                    bgcolor: "secondary.light",
                    border: `1px solid ${theme?.palette?.secondary?.main}`,
                    pointerEvents: "auto",
                  }}
                />
              );
            })}
            <Typography
              variant="body2"
              sx={{
                color: "text.secondary",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 400,
              }}
            >
              {translate(`Search ...`)}
            </Typography>
          </Box>
        </Box>
        <span>
          <CustomLoader
            sx={{
              color: theme?.palette?.secondary?.main,
              height: "20px",
              width: "20px",
            }}
            isLoading={loader}
          />
        </span>
      </Box>
      <Menu
        open={open}
        onClose={handleCloseMenu}
        anchorEl={anchorEl}
        anchorPosition={{ top: 200, left: 400 }}
        PaperProps={{
          sx: {
            marginTop: "8px",
            p: 2,
            width: {
              xs: "100%",
              sm: "100%",
              md: "70%",
              lg: "60%",
              xl: "50%",
            },
          },
        }}
        sx={{}}
      >
        <form
          onSubmit={handleSubmit(() => {
            setFilterState({
              ...initialValues,
              documentClass: filterState?.documentClass,
            });
            handleFilters(initialValues);
            searchApi({
              ...initialValues,
              documentClass: filterState?.documentClass,
            });
            handleCloseMenu();
          })}
        >
          <Typography variant="h6">
            <FilterIcon height={18} width={18} /> {translate(`Filters`)}
          </Typography>
          <Box
            sx={{
              bgcolor: "background.paper",
              p: 1,
              borderRadius: "8px",
            }}
          >
            <InputLabel
              htmlFor="demo-customized-textbox"
              sx={{
                fontSize: "16px",
                fontWeight: 700,
                transform: "translate(0, 1.5px) scale(0.75)",
                color: theme.palette.action.active,
              }}
            >
              {translate(`Document status`)}
            </InputLabel>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "8px",
                py: 1,
              }}
            >
              {optionsStatus?.map((item) => {
                return (
                  <Chip
                    key={`chipoption-${item?.value}`}
                    label={translate(item.value)}
                    size="small"
                    onClick={() => {
                      handleChange("invoiceStatus", item?.enum);
                    }}
                    sx={{
                      borderRadius: "4px",
                      height: "20px",
                      fontSize: "12px",
                      bgcolor:
                        initialValues?.invoiceStatus === item?.enum
                          ? theme?.palette?.secondary?.light
                          : "background.neutral",
                      border: `1px solid ${
                        initialValues?.invoiceStatus === item?.enum
                          ? theme?.palette?.secondary?.main
                          : theme?.palette?.action.disabled
                      }`,
                      pointerEvents: "auto",
                    }}
                  />
                );
              })}
            </Box>
          </Box>
          <Grid
            container
            spacing={2}
            direction={{ xs: "column", sm: "column", md: "row" }}
            sx={{
              py: 1,
              display: "flex",
              flexWrap: {
                xs: "wrap",
                sm: "wrap",
                md: "nowrap",
              },
            }}
          >
            <Grid item xs={12} sm={6} md={4}>
              <StatusDropdown
                state={initialValues?.approvalStatus}
                label={translate(`Approval status`)}
                // sx={{
                //   fontSize: "12px",
                //   pb: "1px",
                // }}
                onChange={(e) => {
                  handleChange("approvalStatus", e.target.value);
                }}
                options={optionsApprovalStatus}
                disabled={loader}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <MyDatePicker
                sx={{
                  fontSize: "12px",
                  padding: "4px 26px 2px 10px",
                }}
                label={`${translate(`finance_screen.start_date`)}`}
                value={initialValues?.fromDate}
                disabled={loader}
                onChange={(newValue) => {
                  handleChange("fromDate", fDate(newValue));
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <MyDatePicker
                sx={{
                  fontSize: "12px",
                  padding: "4px 26px 2px 10px",
                }}
                label={`${translate(`finance_screen.end_date`)}`}
                value={initialValues?.toDate}
                disabled={loader}
                onChange={(newValue) => {
                  handleChange("toDate", fDate(newValue));
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            direction={{ xs: "column", sm: "column", md: "row" }}
            sx={{
              py: 1,
              display: "flex",
              flexWrap: {
                xs: "wrap",
                sm: "wrap",
                md: "nowrap",
              },
            }}
          >
            <Grid item xs={12} sm={6} md={4}>
              <NewTextField
                id="outlined-controlled"
                label={translate(`finance_screen.document_name`)}
                value={initialValues?.documentName}
                disabled={loader}
                onChange={(event: React.FocusEvent<HTMLInputElement>) => {
                  handleChange("documentName", event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StatusDropdown
                state={initialValues?.isERPIntegrated}
                label={translate(`ERP integrated`)}
                // sx={{
                //   fontSize: "12px",
                //   pb: "1px",
                // }}
                onChange={(e) => {
                  handleChange("isERPIntegrated", e.target.value);
                }}
                options={optionsIntegrationStatus}
                disabled={loader}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <MySwitchField
                name={"includeDeleted"}
                value={initialValues?.includeDeleted == true ? true : false}
                checked={initialValues?.includeDeleted == true ? true : false}
                onChange={(event, checked) => {
                  handleChange("includeDeleted", checked);
                }}
                label={
                  <b style={{ color: "#657482" }}>
                    {translate(`Include deleted documents`)}
                  </b>
                }
              />
            </Grid>
          </Grid>
          {enablePaymentFlow && (
            <Box
              sx={{
                bgcolor: "background.paper",
                p: 1,
                borderRadius: "8px",
              }}
            >
              <InputLabel
                htmlFor="demo-customized-textbox"
                sx={{
                  fontSize: "12px",
                  fontWeight: 700,
                  color: theme.palette.action.active,
                }}
              >
                {translate(`payment_status`)}
              </InputLabel>

              <Select
                id="customized-textbox"
                value={initialValues?.paymentStatus}
                input={<NewTextField />}
                sx={{
                  "& .MuiInputBase-input": {
                    position: "relative",
                    backgroundColor: "background.paper",
                    alignItems: "center",
                  },
                }}
                multiple
                onChange={(e) => {
                  handleChange(
                    "paymentStatus",
                    typeof e.target.value === "string" && e.target.value != ""
                      ? e.target.value.split(",")
                      : e.target.value
                  );
                }}
              >
                {paymentStatusOptions.map((paymentStatus) => {
                  return (
                    <MenuItem
                      sx={{
                        fontSize: 12,
                      }}
                      key={paymentStatus}
                      value={paymentStatus}
                    >
                      {translate(paymentStatus)}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          )}

          {documentFieldToggle && (
            <Box
              sx={{
                bgcolor: "background.paper",
                p: 1,
                borderRadius: "8px",
              }}
            >
              <InputLabel
                htmlFor="demo-customized-textbox"
                sx={{
                  fontSize: "12px",
                  fontWeight: 700,
                  color: theme.palette.action.active,
                }}
              >
                {translate(`Document Fields`)}
              </InputLabel>
              <Grid
                container
                spacing={2}
                direction={{ xs: "column", sm: "column", md: "row" }}
                sx={{
                  py: 1,
                  display: "flex",
                  flexWrap: {
                    xs: "wrap",
                    sm: "wrap",
                    md: "nowrap",
                  },
                }}
              >
                {" "}
                <Grid item xs={12} sm={6} md={4}>
                  <GlobalStyles styles={globalStyles} />
                  <NewAutoCompleteField
                    value={fieldsValue?.fieldName}
                    disabled={loader}
                    label={translate(`Field Name`) ?? ""}
                    onChange={(event, newValue) => {
                      if (newValue != null && typeof newValue !== "string") {
                        setFieldsValue({
                          fieldName: newValue?.enum,
                          value: "",
                        });
                      }
                    }}
                    options={fields?.map((item) => {
                      return {
                        enum: item,
                        label: translate(item),
                      };
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <NewTextField
                    id="outlined-controlled"
                    label={translate(`Value`)}
                    value={fieldsValue?.value}
                    disabled={loader}
                    onChange={(event: React.FocusEvent<HTMLInputElement>) => {
                      setFieldsValue({
                        fieldName: fieldsValue?.fieldName,
                        value: event.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "8px",
                    py: 1,
                  }}
                >
                  <span>
                    <IconButton
                      size="small"
                      type="button"
                      sx={{
                        bgcolor: "background.neutral",
                        "&:hover": {
                          bgcolor: "grey.400",
                        },
                      }}
                      onClick={() => {
                        if (fieldsValue?.fieldName === "") return;
                        if (fieldsValue?.value === "") return;
                        if (
                          initialValues.fields?.[fieldsValue?.fieldName] !==
                          undefined
                        )
                          return;
                        setInitialValues({
                          ...initialValues,
                          fields: {
                            ...initialValues?.fields,
                            [fieldsValue?.fieldName]: fieldsValue?.value,
                          },
                        });
                        setFieldsValue({
                          fieldName: "",
                          value: "",
                        });
                      }}
                    >
                      <PlusIcon
                        height={20}
                        width={20}
                        customColor={theme.palette.action.active}
                      />
                    </IconButton>
                  </span>
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "8px",
                  py: 1,
                }}
              >
                {Object.entries(initialValues.fields ?? {})?.map(
                  ([key, value], i) => {
                    return (
                      <Chip
                        key={`chipFields-${key}-${i}`}
                        component={m.div}
                        {...varFade().inRight}
                        label={
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "4px",
                              flexDirection: "row",
                            }}
                          >
                            {translate(key)}
                            <ChevronRightIcon height={10} width={10} /> {value}
                          </Box>
                        }
                        size="small"
                        deleteIcon={
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: 15,
                              height: 15,
                            }}
                          >
                            <Multiply
                              height={10}
                              width={10}
                              customColor={theme?.palette?.action.active}
                            />
                          </Box>
                        }
                        onDelete={() => {
                          let newFilters = [...filters];
                          let index = newFilters.findIndex(
                            (item) => item.name === `fields.${key}`
                          );
                          if (index !== -1) {
                            newFilters.splice(index, 1);
                          }
                          let newFieldObj = { ...initialValues.fields };
                          delete newFieldObj[key];
                          let state = {
                            ...initialValues,
                            fields: newFieldObj,
                            documentClass: filterState?.documentClass,
                          };
                          setInitialValues(state);
                        }}
                        sx={{
                          borderRadius: "4px",
                          height: "20px",
                          fontSize: "12px",
                          bgcolor: "background.paper",
                          border: `1px solid ${theme?.palette?.action.disabled}`,
                          pointerEvents: "auto",
                        }}
                      />
                    );
                  }
                )}
              </Box>
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "8px",
              mt: 1,
            }}
          >
            <Button
              variant="outlined"
              size="small"
              type="button"
              sx={{ width: "fit-content" }}
              onClick={() => {
                handleReset();
              }}
            >
              {translate(`Clear all`)}
            </Button>
            <Button
              variant="contained"
              size="small"
              startIcon={<SearchIcon stroke={"white"} height={20} width={20} />}
              type="submit"
              sx={{ width: "fit-content" }}
            >
              {translate(`finance_screen.search`)}
            </Button>
          </Box>
        </form>
      </Menu>
    </>
  );
};

export default SearchFilters;
