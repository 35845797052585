import { PATH_CONFIGURATION, PATH_DASHBOARD } from "../../../routes/paths";
import { store } from "src/redux/store";
import { ElementType, Suspense, lazy } from "react";
import { CatalogIcon } from "src/assets/icon/Catalog";
import usePermissions from "src/hooks/usePermissions";
import UsersIcon from "src/assets/icon/users";

export const Loadable = (Component: ElementType) => (props: any) => {
  return (
    <Suspense fallback={<></>}>
      <Component {...props} />
    </Suspense>
  );
};
const FileIcon = Loadable(lazy(() => import("src/assets/icon/file")));
const SettingsIcon = Loadable(
  lazy(() => import("./../../../assets/icon/settings"))
);
const GitForkIcon = Loadable(lazy(() => import("src/assets/icon/git-fork")));
const BoxIcon = Loadable(lazy(() => import("src/assets/icon/box")));
const FilterIcon = Loadable(lazy(() => import("src/assets/icon/filter")));
const AnalyticsIcon = Loadable(lazy(() => import("src/assets/icon/Analytics")));

export default () => {
  const {
    org: {
      enablePayments,
      enableCatalogManagement,
      enableNewDocumentConfiguration,
      enableAnalytics,
    },
  } = store.getState()?.organization;
  const { permissionHandle } = usePermissions();

  const navConfig = [
    {
      show: true,
      items: [
        {
          title: "documents",
          path: PATH_DASHBOARD.general.documents.root,
          icon: <FileIcon />,
          role: permissionHandle(["Documents_Read", "Documents_Write"]),
        },
        {
          title: "configuration",
          path: "/configuration",
          icon: <SettingsIcon />,
          role: permissionHandle([
            "MappingConfigurations_Read",
            "MappingConfigurations_Write",
            "DocumentConfigurations_Read",
            "DocumentConfigurations_Write",
            "ExportConfigurations_Read",
            "ExportConfigurations_Write",
            "ExportTemplates_Read",
            "ExportTemplates_Write",
          ]), // || enableDiscrepancyDetection,
          children: [
            {
              title: "Document",
              path: enableNewDocumentConfiguration
                ? `${PATH_CONFIGURATION.general.documentConfiguration}-v2`
                : PATH_CONFIGURATION.general.documentConfiguration,
              role: permissionHandle([
                "DocumentConfigurations_Read",
                "DocumentConfigurations_Write",
              ]),
            },
            {
              title: "Mapping",
              path: PATH_CONFIGURATION.general.inputCSVMapping,
              role: permissionHandle([
                "MappingConfigurations_Read",
                "MappingConfigurations_Write",
              ]),
            },
            {
              title: "Export",
              path: PATH_CONFIGURATION.general.outputCSVMapping,
              role: permissionHandle([
                "ExportConfigurations_Read",
                "ExportConfigurations_Write",
                "ExportTemplates_Read",
                "ExportTemplates_Write",
              ]),
            },
            {
              title: "ERP Sync",
              path: PATH_CONFIGURATION.general.erpSyncConfiguration,
              role: false, // enableDiscrepancyDetection,
            },
          ],
        },
        {
          title: "integration",
          path: PATH_DASHBOARD.general.integration,
          icon: <GitForkIcon />,
          role: permissionHandle(["Integrations_Read", "Integrations_Write"]),
        },
        {
          title: "workflow",
          path: PATH_DASHBOARD.general.workflow,
          icon: <FilterIcon />,
          role: permissionHandle([
            "WorkflowManagement_Read",
            "WorkflowManagement_Write",
            "WorkflowApproval_Read",
            "WorkflowApproval_Write",
          ]),
        },
        {
          title: "suppliers",
          path: PATH_DASHBOARD.general.suppliers,
          icon: <BoxIcon />,
          role: permissionHandle(["Suppliers_Read", "Suppliers_Write"]),
        },
        {
          title: "Customers",
          path: PATH_DASHBOARD.general.customers,
          icon: <UsersIcon />,
          role: permissionHandle(["Customers_Read", "Customers_Write"]),
        },
        {
          title: "catalog",
          path: PATH_DASHBOARD.general.catalog.root,
          icon: <CatalogIcon />,
          role:
            enableCatalogManagement &&
            permissionHandle([
              "CatalogManagement_Read",
              "CatalogManagement_Write",
            ]),
          children: [
            {
              title: "items",
              path: PATH_DASHBOARD.general.catalog.general.items,
              icon: <BoxIcon />,
              role: true,
            },
          ],
        },
        {
          title: "payments",
          path: PATH_DASHBOARD.general.payments,
          icon: <BoxIcon />,
          role:
            enablePayments &&
            permissionHandle(["Payments_Read", "Payments_Write"]),
        },
        {
          title: "analytics",
          path: PATH_DASHBOARD.general.analytics,
          icon: <AnalyticsIcon />,
          role: enableAnalytics,
        },
      ],
    },
  ];
  return { navConfig };
};
