import { useTheme } from "@mui/material";
import React from "react";

const UserIdIcon = () => {
  const theme = useTheme();
  return (
    <span>
      <svg
        width="18"
        height="18"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 17.8252V22.3252L15 20.8252L17 22.3252V17.8252M10 19.3252H5C4.46957 19.3252 3.96086 19.1145 3.58579 18.7394C3.21071 18.3643 3 17.8556 3 17.3252V7.3252C3 6.2252 3.9 5.3252 5 5.3252H19C19.5304 5.3252 20.0391 5.53591 20.4142 5.91098C20.7893 6.28605 21 6.79476 21 7.3252V17.3252C20.9996 17.6759 20.9071 18.0204 20.7315 18.324C20.556 18.6277 20.3037 18.8798 20 19.0552M6 9.3252H18M6 12.3252H9M6 15.3252H8M12 15.3252C12 16.1208 12.3161 16.8839 12.8787 17.4465C13.4413 18.0091 14.2044 18.3252 15 18.3252C15.7956 18.3252 16.5587 18.0091 17.1213 17.4465C17.6839 16.8839 18 16.1208 18 15.3252C18 14.5295 17.6839 13.7665 17.1213 13.2039C16.5587 12.6413 15.7956 12.3252 15 12.3252C14.2044 12.3252 13.4413 12.6413 12.8787 13.2039C12.3161 13.7665 12 14.5295 12 15.3252Z"
          stroke={theme.palette.text.greyLight}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
  );
};

export default UserIdIcon;
