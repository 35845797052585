import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { TFilterState } from "./useFilter";
export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

interface PageQueryProps {
  filters: TFilterState;
  rowsPerPage: number;
  page: number;
  documentFilterFields: string[];
}

const usePageQuery = ({
  filters,
  rowsPerPage,
  page,
  documentFilterFields: fields,
}: PageQueryProps) => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    pageQuery();
  }, [filters, page, rowsPerPage]);
  const pageQuery = () => {
    (
      Object.entries(filters) as Entries<{
        [key: string]:
          | string
          | number
          | boolean
          | null
          | Record<string, string>
          | string[]
          | undefined;
      }>
    ).forEach(([key, value]) => {
      if (key.startsWith("fields")) {
        fields?.forEach((fieldKey) => {
          if (value && value[fieldKey]) {
            addQuery(`${key}.${fieldKey}`, value[fieldKey]?.toString());
          } else {
            removeQuery(`${key}.${fieldKey}`);
          }
        });
      } else if (key.startsWith("paymentStatus")) {
        if (Array.isArray(value)) {
          if (value.length > 0) {
            addQuery(key, value.toString());
          } else {
            removeQuery(key);
          }
        }
      } else if (value && value.toString() !== "{}") {
        addQuery(key, value?.toString());
      } else {
        removeQuery(key);
      }
    });
    if (rowsPerPage) {
      addQuery("rowsPerPage", rowsPerPage?.toString());
    } else {
      removeQuery("rowsPerPage");
    }
    if (page) {
      addQuery("page", page?.toString());
    } else {
      removeQuery("page");
    }
    const url = new URL(window.location.href);
    url.search = searchParams.toString();
    window.history.pushState({}, "", url.toString());
  };

  const addQuery = (key: string, value: string) => {
    if (!searchParams.has(key)) {
      searchParams.append(key, value);
    } else searchParams.set(key, value);
  };

  const removeQuery = (key: string) => {
    searchParams.delete(key);
  };

  const setPageQuery = (key: string, value: string) => {
    searchParams.set(key, value);
    const url = new URL(window.location.href);
    url.search = searchParams.toString();
    window.history.pushState({}, "", url.toString());
  };

  return {
    addQuery,
    removeQuery,
    setPageQuery,
    pageQuery,
  };
};

export default usePageQuery;
