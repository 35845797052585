import { alpha, useTheme } from "@mui/material/styles";
import {
  TableRow,
  TableCell,
  Typography,
  Stack,
  Checkbox,
  MenuItem,
  Popover,
  MenuList,
  ListItemText,
} from "@mui/material";
import { Skeleton, Button, IconButton, Tooltip, Box } from "@mui/material";
import { fDateTime } from "src/utils/formatTime";
import useLocales from "../../../hooks/useLocales";
import {
  MouseEvent,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { FileExtension } from "src/components/FileExtension";
import { DocumentProcessingIcon } from "src/assets/icon/documentProccessed";
import { WorkflowContext } from "../Workflow";
import Label from "src/components/Label";
import usePermissions from "src/hooks/usePermissions";
import { WorkflowApprovalDocuments } from "../type";
import { DownloadIcon } from "src/assets/icon/download";
import { API_GATEWAY } from "src/constants/settings";
import { useSnackbar } from "notistack";
import { GetAsync } from "src/common/httpRequests";
import { applicationTypeMap } from "src/pages/Documents/Finance/table/table";
import TableMenu from "src/pages/Documents/Finance/table/TableMenu";
import { TableMoreMenu } from "src/components/table";
import EyeOpenIcon from "src/assets/icon/eye/On";
import Active from "src/assets/icon/active/Active";
import CloseModalButton from "src/assets/icon/close/CloseModalButton";
import CrossIcon from "src/assets/icon/cross";
import { SmallCloseIcon } from "src/assets/icon/close/Small";
import RemoveFileButton from "src/assets/icon/close/RemoveFileButton";
import ChevronRightIcon from "src/assets/icon/chevron/right";
import ChevronDown from "src/assets/icon/chevron/ChevronDown";
import ChevronDownUpdate from "src/assets/icon/chevron/ChevronDownUpdate";
import { ReactComponent as WarningIcon } from "src/assets/icon/warning.svg";
import DiscrepancyDrawer from "src/pages/Documents/Finance/DiscrepancyDrawer";

type Props = {
  row: WorkflowApprovalDocuments;
  selected: boolean;
  loader: Boolean;
  handleClickOpen: Function;
  setDocument: SetStateAction<any>;
  onSelectRow: VoidFunction;
  onViewDiscrepancyDrawer: (row: WorkflowApprovalDocuments, id: string) => void;
};

const FileDownload = require("js-file-download");

export default function FinanceTableRow({
  row,
  handleClickOpen,
  loader,
  setDocument,
  selected,
  onSelectRow,
  onViewDiscrepancyDrawer,
}: Props) {
  const theme = useTheme();
  const { setFileId, setApprovalNotRequired, handleOpenReviewModal } =
    useContext(WorkflowContext);
  const { translate } = useLocales();
  const {
    fileName,
    status,
    createdOn,
    updatedOn,
    documentId,
    approvalNotRequired,
    documentStatus,
    hasDiscrepancy,
  } = row;
  const { id, isReviewDocument, workflowName, workflowId, documentUrl } = row;
  const { enqueueSnackbar } = useSnackbar();
  const fileExtension = fileName?.split(".").pop()?.toLowerCase();
  const statusDisable = status?.toString() != "0";

  const GetBlobUrlFrom = async (documentUrl: string) => {
    window.open(documentUrl);
  };
  const [tooltipEnabled, setTooltipEnabled] = useState(false);
  const [openMenu, setOpenMenuActions] = useState<null | HTMLElement>(null);
  const [showDownloadOptions, setShowDownloadOptions] =
    useState<HTMLButtonElement | null>(null);
  const checkDisable = documentStatus == 2 || documentStatus == 0;
  const [isDiscrepancyDrawerOpen, setIsDiscrepancyDrawerOpen] = useState(false);

  const toggleDiscrepancyDrawer = () =>
    setIsDiscrepancyDrawerOpen(!isDiscrepancyDrawerOpen);

  const handleShowTooltip = ({ currentTarget }: React.MouseEvent<Element>) => {
    if (currentTarget.scrollWidth > currentTarget.clientWidth) {
      setTooltipEnabled(true);
    }
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const exportCSV = async (id: string) => {
    await GetAsync(
      `${API_GATEWAY}/api/documentservice/DocumentDownload/${id}/csv`,
      true
    )
      .then((response) => {
        console.log("response", response);
        if (response.status === 200) {
          let extension =
            applicationTypeMap[response.headers?.["content-type"]] ?? "csv";
          let file: Blob | string;
          if (extension == "zip") {
            const url = new Blob([response.data], { type: "application/zip" });
            FileDownload(url, `${fileName}.zip`);
            return;
          }
          if (extension == "json") {
            file = `data:text/json;charset=utf-8,${encodeURIComponent(
              response.data.text()
            )}`;
          } else {
            let blob = new Blob([response.data], {
              type: response.headers?.["content-type"] ?? "application/csv",
            });
            file = window.URL.createObjectURL(blob);
          }
          const link = document.createElement("a");
          link.setAttribute("href", file);
          link.setAttribute("download", `${fileName}.${extension ?? "csv"}`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          enqueueSnackbar(response?.data?.Detail, { variant: "error" });
        }
      })
      .catch((err) => {
        console.log("Error", err);
        enqueueSnackbar(translate(`something_went_wrong`), {
          variant: "error",
        });
      });
  };

  const hideTooltip = () => setTooltipEnabled(false);
  const open = Boolean(showDownloadOptions);

  const handleOpenDownloadOptions = (event) =>
    setShowDownloadOptions(event.currentTarget);

  const handleCloseDownloadOptions = () => setShowDownloadOptions(null);

  const { permissionHandle } = usePermissions();

  const writeWorkflowApproval = permissionHandle(["WorkflowApproval_Write"]);

  return (
    <TableRow hover>
      <TableCell size="small" padding="checkbox">
        {loader ? (
          <Skeleton
            variant="rectangular"
            width={15}
            height={15}
            sx={{
              borderRadius: 0.5,
            }}
          />
        ) : (
          <Checkbox checked={selected} onClick={onSelectRow} size="small" />
        )}
      </TableCell>
      <TableCell
        sx={{
          display: !loader ? "flex" : "",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        {loader ? (
          <Skeleton width="100%" />
        ) : (
          <>
            <Box p={0.25}>
              <FileExtension
                ext={fileExtension}
                height="25px"
                width={"25px"}
                style={{
                  color: theme.palette.text.greyLight,
                }}
              />
            </Box>
            <Stack>
              <Tooltip
                title={fileName}
                onMouseEnter={handleShowTooltip}
                onMouseLeave={hideTooltip}
                disableHoverListener={!tooltipEnabled}
                placement="top"
                arrow
              >
                <Typography
                  variant="subtitle2"
                  noWrap
                  sx={{
                    textOverflow: "ellipsis",
                    width: 150,
                    cursor: "pointer",
                  }}
                  onClick={() => GetBlobUrlFrom(documentUrl)}
                >
                  {fileName}
                </Typography>
              </Tooltip>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Label
                  variant={
                    theme.palette.mode === "light" ? "outlined" : "outlined"
                  }
                  color={
                    (documentStatus == 0 && "warning") ||
                    (documentStatus == 1 && "success") ||
                    (documentStatus == 2 && "error") ||
                    (documentStatus == 3 && "error") ||
                    (documentStatus == 4 && "warning") ||
                    (documentStatus == 5 && "success") ||
                    (documentStatus == 6 && "primary") ||
                    "error"
                  }
                  sx={{ textTransform: "capitalize" }}
                >
                  {documentStatus == 0
                    ? `${translate("status.in_process")}`
                    : documentStatus == 1
                    ? `${translate("status.processed")}`
                    : documentStatus == 2
                    ? `${translate("status.failed")}`
                    : documentStatus == 3
                    ? `${translate("status.error")}`
                    : documentStatus == 4
                    ? `${translate("status.need_review")}`
                    : documentStatus == 5
                    ? `${translate("status.reviewed")}`
                    : documentStatus == 6
                    ? `${translate("status.duplicate")}`
                    : "-"}
                </Label>
                {hasDiscrepancy && (
                  <Tooltip
                    title={translate(`discrepancy_detected`)}
                    placement="top"
                    arrow
                  >
                    <span>
                      <IconButton
                        size="small"
                        disabled={checkDisable}
                        onClick={() => onViewDiscrepancyDrawer(row, documentId)}
                        sx={{
                          bgcolor: theme.palette.warning.light,
                          "&:hover": {
                            bgcolor: alpha(theme.palette.warning.dark, 0.3),
                          },
                        }}
                      >
                        <WarningIcon
                          height={16}
                          width={16}
                          stroke={
                            checkDisable
                              ? alpha(theme.palette.warning.dark, 0.3)
                              : theme.palette.warning.dark
                          }
                          color={
                            checkDisable
                              ? alpha(theme.palette.warning.dark, 0.3)
                              : theme.palette.warning.dark
                          }
                          fill={
                            checkDisable
                              ? alpha(theme.palette.warning.dark, 0.3)
                              : theme.palette.warning.dark
                          }
                        />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
              </Box>
            </Stack>
          </>
        )}
      </TableCell>
      <TableCell align="left">
        {loader ? (
          <Skeleton width="100%" />
        ) : (
          <Box>
            <Tooltip title={workflowName} placement="top" arrow>
              <Typography
                variant="body2"
                noWrap
                sx={{
                  textOverflow: "ellipsis",
                  color: "text.greyLight",
                  width: 170,
                }}
              >
                {workflowName}
              </Typography>
            </Tooltip>
          </Box>
        )}
      </TableCell>
      <TableCell align="left">
        {loader ? (
          <Skeleton width="100%" />
        ) : (
          <Box>
            <Typography
              variant="body2"
              noWrap
              sx={{ textOverflow: "ellipsis", color: "text.greyLight" }}
            >
              {updatedOn ? fDateTime(updatedOn) : fDateTime(createdOn)}
            </Typography>
          </Box>
        )}
      </TableCell>
      <TableCell align="left">
        {loader ? (
          <Skeleton width="100%" />
        ) : (
          <>
            {approvalNotRequired ? (
              <Label
                variant={theme.palette.mode === "light" ? "ghost" : "filled"}
                color="info"
              >
                <Typography
                  variant="body2"
                  noWrap
                  sx={{ textOverflow: "ellipsis" }}
                >
                  {translate(`approval_not_required`)}
                </Typography>
              </Label>
            ) : (
              <Label
                variant={theme.palette.mode === "light" ? "ghost" : "filled"}
                color={
                  (status.toString() === "0" && "warning") ||
                  (status.toString() === "1" && "success") ||
                  (status.toString() === "2" && "error") ||
                  "error"
                }
              >
                <Typography
                  variant="body2"
                  noWrap
                  sx={{ textOverflow: "ellipsis" }}
                >
                  {status.toString() === "0"
                    ? `${translate("Pending approval")}`
                    : status.toString() === "1"
                    ? `${translate("approval_status.approved")}`
                    : status.toString() === "2"
                    ? `${translate("approval_status.rejected")}`
                    : ""}
                </Typography>
              </Label>
            )}
          </>
        )}
      </TableCell>
      <TableCell align="left">
        {loader ? (
          <Skeleton width="100%" />
        ) : (
          <>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Tooltip
                title={translate(`Review Document`)}
                placement="top"
                arrow
              >
                <span>
                  <IconButton
                    size="small"
                    disabled={!isReviewDocument || statusDisable}
                    onClick={() => {
                      setFileId(documentId);
                      setDocument({
                        id: id,
                        workflowId: workflowId,
                        documentId: documentId,
                      });
                      handleOpenReviewModal();
                      if (approvalNotRequired === true) {
                        setApprovalNotRequired(true);
                      } else {
                        setApprovalNotRequired(false);
                      }
                    }}
                    sx={{
                      bgcolor: theme.palette.info.light,
                      "&:hover": {
                        bgcolor: alpha(theme.palette.primary.dark, 0.3),
                      },
                    }}
                  >
                    <DocumentProcessingIcon
                      height={18}
                      width={18}
                      customColor={
                        !isReviewDocument || statusDisable
                          ? theme.palette.action.disabled
                          : theme.palette.primary.main
                      }
                    />
                  </IconButton>
                </span>
              </Tooltip>
              {!approvalNotRequired && (
                <>
                  <Button
                    variant="contained"
                    size="small"
                    disabled={statusDisable || !writeWorkflowApproval}
                    sx={{
                      ml: 1,
                      height: 22,
                      bgcolor: "secondary.main",
                      fontSize: 10,
                    }}
                    onClick={() => {
                      handleClickOpen("approve", id, workflowId, documentId);
                    }}
                  >
                    {translate("approval_screen.approve")}
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    disabled={statusDisable || !writeWorkflowApproval}
                    sx={{
                      ml: 1,
                      mr: 1,
                      height: 22,
                      fontSize: 10,
                    }}
                    onClick={() => {
                      handleClickOpen("reject", id, workflowId, documentId);
                    }}
                  >
                    {translate("approval_screen.reject")}
                  </Button>
                </>
              )}
              <TableMoreMenu
                open={openMenu}
                onOpen={handleOpenMenu}
                onClose={handleCloseMenu}
                actions={
                  <>
                    <MenuItem
                      disabled={!isReviewDocument || statusDisable}
                      onClick={() => {
                        setFileId(documentId);
                        setDocument({
                          id: id,
                          workflowId: workflowId,
                          documentId: documentId,
                        });
                        handleOpenReviewModal();
                        if (approvalNotRequired === true) {
                          setApprovalNotRequired(true);
                        } else {
                          setApprovalNotRequired(false);
                        }
                        handleCloseMenu();
                      }}
                    >
                      <EyeOpenIcon
                        height={20}
                        width={20}
                        sx={{
                          height: "auto",
                          width: "auto",
                        }}
                      />
                      {translate(`review_document`)}
                    </MenuItem>
                    <MenuItem
                      disabled={statusDisable || !writeWorkflowApproval}
                      onClick={() => {
                        handleCloseMenu();
                        handleClickOpen("approve", id, workflowId, documentId);
                      }}
                    >
                      <Active width="17" height="17" />
                      {translate("approval_screen.approve")}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleCloseMenu();
                        handleClickOpen("reject", id, workflowId, documentId);
                      }}
                    >
                      <CrossIcon width="17" height="17" />
                      {translate("approval_screen.reject")}
                    </MenuItem>
                    {hasDiscrepancy && (
                      <MenuItem
                        onClick={() => {
                          handleCloseMenu();
                          onViewDiscrepancyDrawer(row, documentId);
                        }}
                      >
                        <CrossIcon width="17" height="17" />
                        {translate("view_discrepancy")}
                      </MenuItem>
                    )}

                    <MenuItem
                      disabled={!isReviewDocument || statusDisable}
                      onClick={(event) => {
                        handleOpenDownloadOptions(event);
                      }}
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Box>
                        <DownloadIcon
                          height={20}
                          width={20}
                          sx={{
                            height: "auto",
                            width: "auto",
                          }}
                        />
                        {translate("download")}
                      </Box>
                      {showDownloadOptions ? (
                        <ChevronDownUpdate
                          customColor={theme.palette.action.active}
                        />
                      ) : (
                        <ChevronRightIcon />
                      )}
                    </MenuItem>
                  </>
                }
              />
              <Popover
                id={id}
                open={open}
                anchorEl={showDownloadOptions}
                onClose={handleCloseDownloadOptions}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "center",
                  horizontal: "right",
                }}
              >
                <MenuList dense>
                  <MenuItem
                    onClick={() => {
                      exportCSV(row.documentId);
                      handleCloseDownloadOptions();
                      handleCloseMenu();
                    }}
                  >
                    <DownloadIcon
                      height={20}
                      width={20}
                      sx={{
                        height: "auto",
                        width: "auto",
                      }}
                    />
                    <ListItemText sx={{ ml: 1 }}>
                      {translate("export_csv")}
                    </ListItemText>
                  </MenuItem>
                </MenuList>
              </Popover>
            </Box>
          </>
        )}
      </TableCell>
    </TableRow>
  );
}
